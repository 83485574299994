<template>
  <div>
    <h1>Regras de coletas para clientes</h1>

    <v-btn
      color="success"
      title="Adicionar Regra"
      @click="$router.push('/nova-regra-cliente-coleta')"
      class="ma-2"
    >
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>

    <v-data-table
      disable-pagination
      hide-default-footer
      :loading="loading"
      :items="rules"
      :headers="headers"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <span>
          <delete-rule :rule="item" @removed="getRules()" />
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import DeleteRule from '../components/DeleteRule.vue';

  export default {
  components: { DeleteRule },
    data() {
      return {
        loading: false,
        rules: [],
        headers: [
          {text: 'Cliente', value: 'partner_name'},
          {text: 'ICMS', value: 'icms'},
          {text: 'Porcentagem', value: 'percentage'},
          {text: 'Frete Mínimo', value: 'min_freight'},
          {text: 'Ações', value: 'actions'},
        ]
      }
    },
    mounted () {
      this.getRules();
    },
    methods: {
      getRules() {
        this.$http.get('api/collect-partner-rules')
        .then(res => {
          this.rules = res.data;
        }).catch(err => {
          console.log(err);

          this.$side({
            type    : 'error',
            msg     : 'Erro ao carregar regras',
            duration: 4000
          });
        });
      }
    },
  }
</script>

<style scoped>

</style>